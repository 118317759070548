import React, { useState, useEffect } from "react";
import { loadingFunc, addtocart1, getProductQuantityInCart } from '../assets/cart.js';


export const  Searchbar = () => {

    const [searchTerm, setSearchTerm] = useState("");  // Estado para el texto ingresado en el campo de búsqueda
    const [allProducts, setAllProducts] = useState([]);  // Estado para almacenar todos los productos
    const [searchResults, setSearchResults] = useState([]);  // Estado para almacenar los resultados de la búsqueda
    const [showDropdown, setShowDropdown] = useState(false); // Estado para controlar la visibilidad del dropdown
    const [isProductsLoaded, setIsProductsLoaded] = useState(false); // Para verificar si los productos ya fueron cargados
  
    // Función para filtrar los productos localmente
    const filterProducts = (searchTerm) => {
      const filteredProducts = allProducts.filter((product) =>
        product.nombre_producto.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredProducts);
    };
  
    // Manejar el evento al hacer clic en el input (descargar productos si no están cargados)
    const handleInputClick = () => {
      if (!isProductsLoaded) {
        fetch(
          "https://testreact.tdestudiodesign.com/backend/index.php?type=products&method=get"
        )
          .then((response) => response.json())
          .then((data) => {
            setAllProducts(data);  // Guardar todos los productos en el estado
            setIsProductsLoaded(true); // Marcar que los productos ya fueron cargados
          })
          .catch((error) => {
            console.error("Error al descargar productos:", error);
          });
      }
    };
  
    // Ejecutar la búsqueda en el JSON local cuando se actualiza el searchTerm
    useEffect(() => {
      if (searchTerm.length >= 2) {
        filterProducts(searchTerm);  // Filtrar productos localmente
        setShowDropdown(true);       // Mostrar el dropdown
      } else {
        setSearchResults([]);        // Limpiar resultados si el término de búsqueda es menor a 2
        setShowDropdown(false);      // Ocultar el dropdown
      }
    }, [searchTerm]);

    return (
        <div id="livesearch" className="live-search">
          <input
            type="text"
            placeholder="Buscar producto..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Actualizar el estado con el valor del input
            onClick={handleInputClick} // Descargar productos al hacer clic si no están descargados
            className="search-inputlive"
          />
    
          {/* Dropdown de resultados */}
          {showDropdown && (
            <ul className="dropdownlive">
              {searchResults.length > 0 ? (
                searchResults.map((product) => (
            <li key={product.id_producto} className="dropdown-itemlive">
              <span className="liveResult"><a href={`/producto?id=${product.id_producto}`} className="fs-6">{product.nombre_producto}</a>
              <em>${product.precio_producto}</em></span>
              
                          <button className="cd-button btn w-25 py-1 px-1 mx-0 rounded btn-success display-4"
                              onClick={() => {
                                loadingFunc(product.id_producto);
                                addtocart1(
                                  product.nombre_producto, 
                                  product.id_producto, 
                                  product.precio_producto - (product.precio_producto * product.descuento_producto) / 100, 
                                  product.descuento_producto,
                                  product.Impuestos_producto
                                );
                                
                              }}                          > 
                            <i id={product.id_producto} className="bi-cart-plus me-1"></i>
                          </button>
            </li>
          ))
        ) : (
          <li className="dropdown-item">No se encontraron resultados</li>
        )}
      </ul>
    )}
  </div>
);

}
export default Searchbar;
