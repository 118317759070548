import React, { useState, useEffect } from 'react';
import {GetMonedas} from './GetMonedas';

export const  Adminhome = () => {

  const [orders, setOrders] = useState([]); // Asegurarse de inicializar como un array vacío

useEffect(() => {
  fetch('https://testreact.tdestudiodesign.com/backend/index.php?type=order&method=getlastfive')
  
    .then((response) => response.json())
    .then((data) => {
      if (Array.isArray(data)) {
        setOrders(data);  // Asegurar que sea un array
      } else {
        console.error('El formato de los datos no es correcto, se esperaba un array', data);
        setOrders([]);  // Si no es un array, establecer un array vacío
      }
    })
    .catch((error) => console.error('Error al obtener órdenes:', error));
}, []);

  return (
    <div>
        <GetMonedas />
        
        
        <div className="image-box py-2" id="listarapida">
                <a className="images btn rounded p-4 text-white" href="./controler/new/new_product.php">Añadir producto</a>
                <a className="images btn rounded p-4 text-white" href="./controler/new/new_category.php">Añadir Categoria</a>
                <a className="images btn rounded p-4 text-white" href="./controler/new/new-user.php">Añadir Usuario</a>
                <a className="images btn rounded p-4 text-white" href="./controler/list/products-list.php">Productos</a>
                <a className="images btn rounded p-4 text-white" href="./controler/list/user-list.php">Usuarios</a>
                <a className="images btn rounded p-4 text-white" href="./controler/list/order-list.php">Pedidos</a>
        </div>

        <table className="table table-info table-striped" border="1">
    <thead>
      <tr>
        <th>Codigo</th>
        <th>Fecha</th>
        <th>Cliente</th>
        <th>Estado</th>
        <th>Total</th>
        <th>Pago</th>
        <th>Envio</th>
      </tr>
    </thead>
    <tbody>
      {orders.length > 0 ? (
        orders.map((order) => (
          <tr key={order.id_pedido}>
            <td>{order.codigo_pedido}</td>
            <td>{order.fecha_pedido}</td>
            <td>{order.nombre_facturacion} {order.apellido_facturacion}</td>
            <td>{order.estado_pedido}</td>
            <td>{order.total_pedido}</td>
            <td>{order.metodo_de_pago}</td>
            <td>{order.metodo_de_envio}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="7">No hay órdenes disponibles</td>
        </tr>
      )}
    </tbody>
  </table>

</div>
  )
}

export default Adminhome;

