import React, { useState, useEffect } from 'react';
import {MiniCart} from './MiniCart';
import {CartSummary} from './CartSummary';

export const Checkout = () => {
  const [isShippingSameAsBilling, setIsShippingSameAsBilling] = useState(true);

  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    dni: '',
    telefono: '',
    mail: '',
    calle: '',
    localidad: '',
    provincia: '',
    codigoPostal: '',
    direccionEnvio: '',
    ciudadEnvio: '',
    provinciaEnvio: '',
    codigoPostalEnvio: ''
  });

  useEffect(() => {
    // Cargar datos de sesión o API aquí
    // Simulamos datos de sesión para el ejemplo
    const sessionData = {
      name: 'Juan',
      lastname: 'Pérez',
      tel: '123456789',
      mail: 'juanperez@example.com',
      address: 'Calle Falsa 123',
      city: 'Ciudad',
      state: 'Provincia',
      cp: '12345'
    };

    setFormData({
      ...formData,
      nombre: sessionData.name || '',
      apellido: sessionData.lastname || '',
      telefono: sessionData.tel || '',
      mail: sessionData.mail || '',
      calle: sessionData.address || '',
      localidad: sessionData.city || '',
      provincia: sessionData.state || '',
      codigoPostal: sessionData.cp || ''
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = () => {
    setIsShippingSameAsBilling(!isShippingSameAsBilling);
    if (isShippingSameAsBilling) {
      setFormData({
        ...formData,
        direccionEnvio: formData.calle,
        ciudadEnvio: formData.localidad,
        provinciaEnvio: formData.provincia,
        codigoPostalEnvio: formData.codigoPostal
      });
    } else {
      setFormData({
        ...formData,
        direccionEnvio: '',
        ciudadEnvio: '',
        provinciaEnvio: '',
        codigoPostalEnvio: ''
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí iría la lógica para enviar los datos al backend
    console.log('Formulario enviado', formData);
  };

  return (
    <div>
      <h1 className="text-center fw-bold">Finalizar Compra</h1>
      
      {/* Formulario de Datos de Facturación */}
      <form id="new_order" onSubmit={handleSubmit}>
        <div className="div d-flex flex-row flex-wrap align-content-start justify-content-center align-items-start">
          <div className="w-70check py-5 px-4 bc-col" style={{ display: 'table-cell' }}>


          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header">
                <button class="accordion-button bg-primary text-white fw-bold border-hover" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Datos de Facturación
                </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div className="d-flex flex-row flex-wrap justify-content-between py-4">
                            <input className="form-control inputname" type="text" name="nombre" placeholder="Nombre" value={formData.nombre} onChange={handleInputChange} required />
                            <input className="form-control inputname" type="text" name="apellido" placeholder="Apellido" value={formData.apellido} onChange={handleInputChange} required />
                            <input className="form-control inputname" type="number" name="dni" placeholder="DNI" value={formData.dni} onChange={handleInputChange} required />
                            <input className="form-control inputname" type="number" name="telefono" placeholder="Telefono" value={formData.telefono} onChange={handleInputChange} required />
                            <input className="form-control inputdata" type="email" name="mail" placeholder="Mail" value={formData.mail} onChange={handleInputChange} required />
                            <input className="form-control inputname" type="text" id="address" name="calle" placeholder="Calle y numero" value={formData.calle} onChange={handleInputChange} />
                            <input className="form-control inputname" type="text" id="city" name="localidad" placeholder="Localidad" value={formData.localidad} onChange={handleInputChange} />
                            <input className="form-control inputname" type="text" id="state" name="provincia" placeholder="Provincia" value={formData.provincia} onChange={handleInputChange} required />
                            <input className="form-control inputname" type="number" id="cp" name="codigoPostal" placeholder="Codigo Postal" value={formData.codigoPostal} onChange={handleInputChange} required />
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed bg-primary fw-bold text-white border-hover" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Datos de Envío
                </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <input type="checkbox" name="enviofacturacion" className="input" checked={!isShippingSameAsBilling} onChange={handleCheckboxChange} />
                        Enviar a otra Dirección?
                        {!isShippingSameAsBilling && (
                            <div className="d-flex flex-row flex-wrap justify-content-between py-4" id="shipping">
                                <input className="form-control inputname" id="address_shipping" type="text" name="direccionEnvio" placeholder="Calle y numero" value={formData.direccionEnvio} onChange={handleInputChange} />
                                <input className="form-control inputname" id="city_shipping" type="text" name="ciudadEnvio" placeholder="Localidad" value={formData.ciudadEnvio} onChange={handleInputChange} />
                                <input className="form-control inputname" id="state_shipping" type="text" name="provinciaEnvio" placeholder="Provincia" value={formData.provinciaEnvio} onChange={handleInputChange} />
                                <input className="form-control inputname" id="cp_shipping" type="number" name="codigoPostalEnvio" placeholder="Codigo Postal" value={formData.codigoPostalEnvio} onChange={handleInputChange} />
                            </div>
                        )}                
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button bg-primary fw-bold text-white  border-hover collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Metodo de Envio
                    </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <textarea className="inputdata" rows="7" cols="150" name="nota_cliente" value={formData.nota_cliente} onChange={handleInputChange}></textarea>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button bg-primary fw-bold text-white border-hover collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                        Metodo de Pago
                    </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <textarea className="inputdata" rows="7" cols="150" name="nota_cliente" value={formData.nota_cliente} onChange={handleInputChange}></textarea>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button bg-primary fw-bold text-white border-hover collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                        Notas adicionales
                    </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <h4>Agrega notas sobre tu pedido o sobre el envio</h4>
                        <textarea className="inputdata" rows="7" cols="150" name="nota_cliente" value={formData.nota_cliente} onChange={handleInputChange}></textarea>
                    </div>
                </div>
            </div>
        </div>


           

        </div>
          
          {/* Resumen de Compra */}
          <div className="w-40check py-5 px-4 bc-col colres align-center">
            {/* Aquí puedes añadir la lógica para mostrar los productos del carrito */}
            <MiniCart />
            <div className="p-3 d-grid gap-2">
              <button className="btn btn-primary" type="submit">Realizar Compra</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Checkout;
