import React, { useState, useEffect } from 'react';

export const CategoryList = ({ onCategoryChange }) => {
  const [openCategories, setOpenCategories] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]); // Nuevo estado para categorías seleccionadas

  useEffect(() => {
    fetch('https://testreact.tdestudiodesign.com/backend/index.php?type=category&method=get')
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error('Error al obtener categorías:', error));
  }, []);

  const toggleSubcategories = (id_categoria) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [id_categoria]: !prevState[id_categoria],
    }));
  };

  // Manejar el cambio de selección de categoría
  const handleCategoryChange = (e) => {
    const categoryId = parseInt(e.target.value);
    let updatedCategories = [];

    if (e.target.checked) {
      updatedCategories = [...selectedCategories, categoryId];
    } else {
      updatedCategories = selectedCategories.filter(id => id !== categoryId);
    }

    setSelectedCategories(updatedCategories);
    onCategoryChange(updatedCategories); // Enviar las categorías seleccionadas al componente padre
  };

  return (
    <div>
      {categories.map((category) => (
        <div key={category.id_categoria} id="col" className="col-12 col-lg-12 text-start">
          <div id="row" className="row">
            <span>
              <input 
                type="checkbox" 
                id={category.id_categoria} 
                name={category.nombre_categoria} 
                value={category.id_categoria} 
                onChange={handleCategoryChange} // Manejar el cambio de selección
              /> 
              <span className='px-2' onClick={() => toggleSubcategories(category.id_categoria)}>
                {category.nombre_categoria}
              </span>
              <br />
            </span>

            {openCategories[category.id_categoria] && (
              <div className='text-start px-5'>
                {category.subcategories.map((subcategory) => (
                  subcategory.id ? (
                    <span key={subcategory.id}>
                      <input 
                        type="checkbox" 
                        id={subcategory.id} 
                        name={subcategory.nombre} 
                        value={subcategory.id} 
                        onChange={handleCategoryChange} // Manejar el cambio de selección
                      /> 
                      <span className='px-2'> 
                        {subcategory.nombre}
                        <br />
                      </span>
                    </span>
                  ) : null
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryList;