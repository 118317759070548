import React, { useState, useEffect } from 'react';

export const Cart = () => {
  const [cartProducts, setCartProducts] = useState([]);

  // Cargar los productos del carrito cuando el componente se monta
  useEffect(() => {
    const cartArrayProd = JSON.parse(localStorage.getItem('cart') || '[]');
    setCartProducts(cartArrayProd);
  }, []);

  // Función para actualizar la cantidad de un producto en el carrito
  const updateQuantity = (productId, newQty) => {
    if (newQty < 1) return;

    const updatedCartProducts = cartProducts.map((product) => {
      if (product.id === productId) {
        return { ...product, qty: newQty };
      }
      return product;
    });

    // Actualiza el carrito en localStorage
    localStorage.setItem('cart', JSON.stringify(updatedCartProducts));
    // Actualiza el estado del carrito
    setCartProducts(updatedCartProducts);
  };

  // Función para eliminar un producto del carrito
  const removeProduct = (productId) => {
    const updatedCartProducts = cartProducts.filter((product) => product.id !== productId);

    // Actualiza el carrito en localStorage
    localStorage.setItem('cart', JSON.stringify(updatedCartProducts));
    // Actualiza el estado del carrito
    setCartProducts(updatedCartProducts);
  };

  // Función para calcular el subtotal de cada producto
  const calculateSubtotal = (price, qty) => {
    return price * qty;
  };

  // Función para calcular el total
  const calculateTotal = () => {
    return cartProducts.reduce((total, product) => {
      return total + calculateSubtotal(product.price, product.qty);
    }, 0);
  };

  return (
    <div style={{ padding: '2% 7%', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <table className="table table-success table-striped fs-6">
        <thead>
          <tr className="text-uppercase fs-6 fw-semibold">
            <th className="align-middle text-center">Id</th>
            <th className="align-middle text-center">Nombre</th>
            <th className="align-middle text-center">Cantidad</th>
            <th className="align-middle text-center">Precio Unitario</th>
            <th className="align-middle text-center">Subtotal</th>
            <th className="align-middle text-center">Eliminar</th>
          </tr>
        </thead>
        <tbody id="tableProd">
          {cartProducts.length === 0 ? (
            <tr>
              <td colSpan="6" className="text-center">El carrito está vacío</td>
            </tr>
          ) : (
            cartProducts.map((product, index) => (
              <tr key={index}>
                <td className="align-middle text-center">{product.id}</td>
                <td className="align-middle text-center">{product.name}</td>
                
                {/* Input para modificar la cantidad */}
                <td className="align-middle text-center">
                  <input
                    type="number"
                    className="form-control inputqty"
                    value={product.qty}
                    onChange={(e) => updateQuantity(product.id, parseInt(e.target.value))}
                    min="1"
                  />
                </td>

                <td className="align-middle text-center">${product.price}</td>
                <td className="align-middle text-center">${calculateSubtotal(product.price, product.qty).toFixed(2)}</td>

                {/* Botón para eliminar el producto */}
                <td className="align-middle text-center">
                  <button 
                    className="btn btn-danger"
                    onClick={() => removeProduct(product.id)}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className="w-100">
        <a className="btn btn-primary float-end" href={'/Checkout'}>
          Finalizar Compra
        </a>
        <br /><br /><br />
      </div>

      <div className="w-100">
        <table className="table table-success w-50 text-center fs-5 fw-semibold float-end table-striped-columns" id="tableFoot">
          <thead>
            <tr>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${calculateTotal().toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Cart;
