import React, { useState, useEffect } from 'react';

export const  GetMonedas = () => {
  
  const [monedas, setMonedas] = useState([]);


  useEffect(() => {
    fetch('https://testreact.tdestudiodesign.com/backend/index.php?type=moneda')
      .then((res) => {
        if (!res.ok) {
          throw new Error('Error en la respuesta del servidor');
        }
        return res.json();
      })
      .then((data) => {
        if (data.length > 0) {
          // Convertimos el string value_ajustes a un objeto
          const ajustesObj = JSON.parse(data[0].value_ajustes);
  
          // Aquí puedes hacer lo que necesites con esas claves y valores
          setMonedas(ajustesObj); // Guardar el objeto deserializado en el estado
        }
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, []);


  return (
    <div>
        {monedas && (
      <div id="monedas">
        {Object.entries(monedas).map(([key, value]) => (
          <span key={key} className="fw-bold display-4 fs-6">
            {key}:  <em>{value}</em>
            &nbsp;&nbsp;|&nbsp;&nbsp;</span>
        ))}
      </div>
    )}
        
</div>
  )
}

export default GetMonedas;

