import React, { useState, useEffect } from 'react';
import { CartSummary} from './CartSummary';
export const MiniCart = () => {
  const [cartProducts, setCartProducts] = useState([]);


  // Cargar los productos del carrito cuando el componente se monta
  useEffect(() => {
    const cartArrayProd = JSON.parse(localStorage.getItem('cart') || '[]');
    setCartProducts(cartArrayProd);
  }, []);
  
  // Función para actualizar la cantidad de un producto en el carrito
  const updateQuantity = (productId, newQty) => {
    if (newQty < 1) return;

    const updatedCartProducts = cartProducts.map((product) => {
      if (product.id === productId) {
        return { ...product, qty: newQty };
      }
      return product;
    });

    // Actualiza el carrito en localStorage
    localStorage.setItem('cart', JSON.stringify(updatedCartProducts));
    // Actualiza el estado del carrito
    setCartProducts(updatedCartProducts);

  };

  // Función para eliminar un producto del carrito
  const removeProduct = (productId) => {
    const updatedCartProducts = cartProducts.filter((product) => product.id !== productId);

    // Actualiza el carrito en localStorage
    localStorage.setItem('cart', JSON.stringify(updatedCartProducts));
    // Actualiza el estado del carrito
    setCartProducts(updatedCartProducts);
  };

  // Función para calcular el subtotal de cada producto
  const calculateSubtotal = (price, qty) => {
    return price * qty;
  };

  // Función para calcular el total
  const calculateTotal = () => {
    return cartProducts.reduce((total, product) => {
      return total + calculateSubtotal(product.price, product.qty);
    }, 0);
  };

  const handleInputChange = (e, product) => {
    const value = parseInt(e.target.value);
    
    updateQuantity(product.id, value);
    calculateDiscount(product.price, value, product.desc);
  };


  const calculatetaxes = () => {
    return cartProducts.reduce((totalTaxes, product) => {
      // ((price * desc) / 100) * qty
      const taxes = ((product.price * product.tax) / 100) * product.qty;
      return totalTaxes + taxes;
    }, 0);
  };

  const calculateDiscount = () => {
    return cartProducts.reduce((totalDiscount, product) => {
      // ((price * desc) / 100) * qty
      const discount = ((product.price * product.desc) / 100) * product.qty;
      return totalDiscount + discount;
    }, 0);
  };

  return (
    <div style={{ padding: '2% 7%', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <table className="table-Light fs-6">
        <thead>
          <tr className="border-bottom py-3 border-dark-subtle text-uppercase fs-6 fw-semibold">
            <th  scope="col" className="align-middle text-start">Nombre</th>
            <th  scope="col" className="align-middle text-start">Cantidad</th>
            <th  scope="col" className="align-middle text-start">Subtotal</th>
          </tr>
        </thead>
        <tbody id="tableProd">
          {cartProducts.length === 0 ? (
            <tr>
              <th  scope="col" colSpan="6" className="text-center">El carrito está vacío</th>
            </tr>
          ) : (
            cartProducts.map((product, index) => (
              <tr key={index} className='border-bottom border-dark-subtle py-3 '>
                <td className="w-50 align-middle text-start">{product.name}</td>
                
                {/* Input para modificar la cantidad */}
                <td scope="col" className="align-middle text-center">
                  <input
                    type="number"
                    className="form-control inputqty text-center"
                    value={product.qty}
                    onChange={(e) => handleInputChange(e, product)}
                    min="1"
                  />
                </td>

                <td className="align-middle text-end">${calculateSubtotal(product.price, product.qty).toFixed(2)}
                    </td>

                {/* Botón para eliminar el producto */}
                <td className="w-15 align-middle h-100 my-2 float-end">
                  <button style={{fontSize:"9px!important"}}
                    className="px-2 text-white bg-danger rounded-circle"
                    onClick={() => removeProduct(product.id)}
                  >
                    X
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <br />
      <br />


      <div className="w-100">
        <table className="table w-100 float-end table-striped-columns" id="tableFoot">
          <tbody>
          <tr className='input-group my-1'>
              <td className='w-50 bg-primary text-white input-group-text text-start fs-6 fw-semibold'>Impuestos</td>
              <td className='w-50 input-group-text text-end px-3'>${calculatetaxes().toFixed(2)}</td>
            </tr>
            <tr className='input-group my-1'>
              <td className='w-50 bg-primary text-white input-group-text text-start fs-6 fw-semibold'>Descuento</td>
              <td className='w-50 input-group-text text-end px-3'>${calculateDiscount().toFixed(2)}</td>
            </tr>
            <tr className='input-group my-1' >
              <td className='w-50 bg-primary text-white input-group-text text-start fs-5 fw-semibold'>Total</td>
              <td className='w-50 input-group-text text-end px-3'>${calculateTotal().toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MiniCart;
