import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { cartItems} from './assets/cart.js';
import {Searchbar} from './pages/Searchbar';

export default function Header() {
  useEffect(() => {
    cartItems();
  }, []);
  
  return (
    <header>

<section data-bs-version="5.1" className="menu menu2 cid-tM5Cw1aWeE my-4" id="menu2-24">
  <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
    <div className="container-fluid">
      <div className="navbar-brand">
        <span className="navbar-logo">
          <Link to="/">
            <img
              src="assets/images/b8b032ad88554de0939020f801a95022-2-removebg-preview.webp"
              alt="..."
              style={{ height: "4.7rem" }}
            />
          </Link>
        </span>
      </div>
      <Searchbar />
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-bs-toggle="collapse"
        data-target="#navbarSupportedContent"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <div className="hamburger">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav nav-dropdown" data-app-modern-menu="true">
          <li className="nav-item">
            <a className="nav-link px-2 text-white display-4 fs-6" href={`/`} >
              Inicio
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link px-2 text-white display-4 fs-6" href={`/Categoria`} >
              Categorias
            </a>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link px-2 text-white text-primary dropdown-toggle display-4 fs-6"
              href="mas-servicios.php"
              data-toggle="dropdown-submenu"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              Servicios
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdown-279">
              <a href={`/Producto`} className="text-white text-primary dropdown-item display-4 fs-6">
                <span
                  className="socicon socicon-google mbr-iconfont mbr-iconfont-btn"
                  style={{ fontSize: "20px" }}
                ></span>
                Publicidad Google
              </a>
              <a href={`/Producto`} className="text-white text-primary show dropdown-item display-4 fs-6">
                <span
                  className="socicon socicon-facebook mbr-iconfont mbr-iconfont-btn"
                  style={{ fontSize: "20px" }}
                ></span>
                Publicidad Facebook
              </a>
              <a href={`/Producto`} className="text-white text-primary show dropdown-item display-4 fs-6">
                <span
                  className="socicon socicon-instagram mbr-iconfont mbr-iconfont-btn"
                  style={{ fontSize: "20px" }}
                ></span>
                Publicidad Instagram
              </a>
              <a href={`/Producto`} className="text-white text-primary show dropdown-item display-4 fs-6">
                <span
                  className="mobi-mbri mobi-mbri-globe-2 mbr-iconfont mbr-iconfont-btn"
                  style={{ fontSize: "20px" }}
                ></span>
                Posicionamiento SEO
              </a>
            </div>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link px-2 text-white text-primary dropdown-toggle display-4 fs-6"
              href="mas-servicios.php"
              data-toggle="dropdown-submenu"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              Trabaja con Nosotros
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdown-326">
              <a
                className="text-white text-primary show dropdown-item display-4 fs-6"
                href="mas-servicios.php"
              >
                <span
                  className="mbri-cursor-click mbr-iconfont mbr-iconfont-btn"
                  style={{ fontSize: "20px" }}
                ></span>
                Ventas Paginas Web
              </a>
              <a
                className="text-white text-primary show dropdown-item display-4 fs-6"
                href="mas-servicios.php"
              >
                <span
                  className="mbrib-to-ftp mbr-iconfont mbr-iconfont-btn"
                  style={{ fontSize: "20px" }}
                ></span>
                Venta Hosting
              </a>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link px-2 text-white display-4 fs-6" href={`/Usuario`}>
              Nombre
            </a>
          </li>
        </ul>

        <div className="navbar-buttons mbr-section-btn">
          <a className="btn btn-danger display-4 fs-6" href="https://wa.me/541151215645">
            <span className="socicon mx-2 socicon-whatsapp mbr-iconfont mbr-iconfont-btn"></span>
            Contactar
          </a>
          <a
            id="cart"
            className="btn btn-danger align-top display-4"
            style={{ minHeight: "45.4px", fontSize: "10px" }}
            href="cart"
          >
            <i id="carticon" className="bi bi-cart fs-6"></i>
            <span id="cartcant"></span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</section>


    </header>
  )
}
