import React, {useState} from 'react'
import { Link } from "react-router-dom";

export const Usuario = () => {
  

    const[text, setText]= useState()

    const textOnChange = (event) => {
        setText(event.target.value);
    }

  return (
    <div>

        <input type='text' value={text} onChange={textOnChange} />
        <button>actualizar usuario</button>
        <p>texto: {text}</p>
        <Link to="/">Haz clic para ver la página Home</Link>
        <Link to="/Producto">Haz clic para ver la página de Producto</Link>
    </div>
  )
}