export function addtocart1(nombre,id, precio,descuento,taxes){
    let cant;

const inputQuantity = document.querySelector('#inputQuantity');

if (inputQuantity) {
    cant = inputQuantity.value;

} else {
    cant = 1;
} 
    const qty = cant;
    const name = nombre;
    const price = precio;
    const desc = descuento;
    const tax = taxes;
    const id_e = id;
console.log(id);
    const localcart = { 
        'id': id_e,
        'name': name,
        'price': price,
        'qty': qty,
        'tax': tax,
        'desc': desc
    };            
    const cartArrayProd = JSON.parse(localStorage.getItem("cart") || "[]");
    if(cartArrayProd.find(x => x.id === id)){
        let newQty = cartArrayProd.filter(x => x.id === id);
        newQty[0]['qty'] = Number(qty) + Number(newQty[0].qty);
        localStorage.setItem("cart", JSON.stringify(cartArrayProd));
    }else{
        cartArrayProd.push(localcart);
        localStorage.setItem("cart", JSON.stringify(cartArrayProd));    
    }
    cartItems()

}




export function updatecart(){
    
    const qty = document.querySelector('#inputQuantity').value;
    const name = document.querySelector('#prodName').innerText;
    const price = document.querySelector('#prodDesc').innerText;
    const id = document.querySelector('#idProd').innerText;
    const desc = document.querySelector('#desc').value;
    const tax = document.querySelector('#tax').value;



    const localcart = { 
        'id': id,
        'name': name,
        'price': price,
        'qty': qty,
        'tax': tax,
        'desc': desc
    };            
    const cartArrayProd = JSON.parse(localStorage.getItem("cart") || "[]");
    if(cartArrayProd.find(x => x.id === id)){
        let newQty = cartArrayProd.filter(x => x.id === id);
        newQty[0]['qty'] = Number(qty);
        localStorage.setItem("cart", JSON.stringify(cartArrayProd));

    }else{
        cartArrayProd.push(localcart);
        localStorage.setItem("cart", JSON.stringify(cartArrayProd));    
    }
}


export function update(event) {  // Recibe 'event' como parámetro
    const cartArrayProd = JSON.parse(localStorage.getItem("cart") || "[]");
    let idCart = event.target.id;
    let idQty = event.target.value;
    console.log(event.target.value, event.target.id);

    if (cartArrayProd.find(x => x.id === idCart)) {
        let newQty = cartArrayProd.filter(x => x.id === idCart);
        newQty[0]['qty'] = Number(idQty);
        localStorage.setItem("cart", JSON.stringify(cartArrayProd));
    }
}


export function deleteCart (){
localStorage.removeItem('cart');
}



export function deleteProd(event) {
    // Obtener el ID del producto desde el botón que disparó el evento
    let idprod = event.target.id;

    // Obtener y actualizar el carrito en localStorage
    let cartArrayProd = JSON.parse(localStorage.getItem("cart") || "[]");
    const newArr = cartArrayProd.filter(object => object.id !== idprod);
    localStorage.setItem("cart", JSON.stringify(newArr));

    // Encontrar la fila en la tabla correspondiente al ID del producto y eliminarla
    let rowToDelete = document.querySelector(`tr[data-product-id="${idprod}"]`);
    if (rowToDelete) {
        rowToDelete.remove();  // Eliminar la fila directamente
    } else {
        console.warn(`No se pudo encontrar la fila con data-product-id="${idprod}"`);
    }
}

export function cartDisplay(){
    
    var hst = document.getElementById("tableProd");
    var retrievedScores = JSON.parse(localStorage.getItem("cart"));
    var strigy = JSON.stringify(retrievedScores);

    var total = [];
    
    for (var i = 0; i < retrievedScores.length; i++) {
        var tot = i+1;
        hst.innerHTML += '<form onsubmit="deleteProd(event)"> <tr id="prod'
        + retrievedScores[i].id +'" class="fs-6 fw-semibold w-100"><td class="align-middle text-center w-10"><p id="idProd">'
         + retrievedScores[i].id + '</p></td><td class="w-25 align-middle text-center"><p id="prodName">'
          + retrievedScores[i].name + '</p></td><td class="w-25 align-middle text-center"><input class="inputqty" type="number" id="'
           + retrievedScores[i].id +'"  value="'
            + retrievedScores[i].qty + '"onclick="update()"></td><td class="w-25 align-middle text-center"><p id="prodDesc">$'
           + retrievedScores[i].price + '</p></td><td class="w-25 align-middle text-center"><p id="prodDesc">$'
            + retrievedScores[i].price*retrievedScores[i].qty + '</p></td><td class="w-15 botonel align-middle text-center"><a class="btn btn btn-primary fs-3 p-4" id="'
             + retrievedScores[i].id + '"  name="' + tot +'" style="background-color:#dc3545!important;" onclick="deleteProd()"></a></td></tr></form>';
        var subtotal = retrievedScores[i].price*retrievedScores[i].qty;
        total.push(subtotal);
    }
    
    let sum = 0;
    for (let i = 0; i < total.length; i++ ) {
        sum += total[i];
    }
    
    var footer = document.getElementById("tableFoot");
    footer.innerHTML += '<tr><th>Total</th><td>$'+sum+'</td></tr>';
    
}

export function checkoutDisplay(){
    
    var hst = document.getElementById("tableProd1");
    var retrievedScores = JSON.parse(localStorage.getItem("cart"));
    var strigy = JSON.stringify(retrievedScores);
    var tableCheck = document.getElementById("tableCheck1");
    var totalArr = [];
    var prod_order = "";

    for (var a = 0; a < retrievedScores.length; a++) {
        tableCheck.innerHTML += '<tr><td class="w-25 align-middle"><p id="prodName">'+ retrievedScores[a].name + ' x ' + retrievedScores[a].qty +'</p><input type="hidden" name="prod_order[]" value="'+ retrievedScores[a].id + '-' + retrievedScores[a].name + '-' + retrievedScores[a].qty + '-' + retrievedScores[a].price +'-' + retrievedScores[a].desc + '-' + retrievedScores[a].tax +'" /></td><td class="w-25 align-middle"><p id="prodDesc">$'+  retrievedScores[a].price*retrievedScores[a].qty +'</p></td></tr>';
        var totalCheck = retrievedScores[a].price*retrievedScores[a].qty;
        totalArr.push(totalCheck);
    }
    
    let sumcheck = 0;
    for (let i = 0; i < totalArr.length; i++ ) {
        sumcheck += totalArr[i];
    }
    console.log(sumcheck);

 var tableFootcheck = document.getElementById("tableFootcheck");
    tableFootcheck.innerHTML += '<tr><th>Total</th><td>$'+sumcheck+'<input type="hidden" name="total" value="'+ sumcheck +'" /></td></tr>';

}

export function itemcount() {
    const cartArrayProd = JSON.parse(localStorage.getItem("cart") || "[]");

    // Verifica si el carrito está vacío
    if (cartArrayProd.length === 0) {
        console.log('El carrito está vacío');
    }else{

    cartArrayProd.forEach(product => {
        const idsearch = "#prod" + product.id;
        const cartItemElement = document.querySelector(idsearch);
        console.log(idsearch);
        if (cartItemElement) {
            cartItemElement.innerHTML = product.qty;
            console.log(product.qty);

        } else {
            console.error('No se encontró el elemento con id:', cartItemElement);
        }
    })}
}



export function cartItems() {
    var cart = document.getElementById("cartcant");
    var cartGet = JSON.parse(localStorage.getItem("cart"));
    var cantArr = 0;
    if(cartGet != null){
        for (var a = 0; a < cartGet.length; a++) {
            var totalcant = cartGet[a].qty;

            cantArr += totalcant;

        }
        cart.innerHTML = cantArr;
    }else{
        cart.innerHTML = cantArr;
    }
    }

    var intico;

export function loadingFunc(id) {
        var element = document.getElementById(id);
        element.classList.add('fa', 'fa-spinner', 'fa-spin');
        element.classList.remove("bi-cart-plus");


        intico = setInterval(function() {
            displaycartic(id);
        }, 150);
    }

    export   function displaycartic(id) {
        var element = document.getElementById(id);

        element.classList.remove('fa', 'fa-spinner', 'fa-spin');
        element.classList.add("bi-cart-plus");
        clearInterval(intico);
    }