import React, {useState} from 'react'
import { Link } from "react-router-dom";

export const Producto = () => {
  

    const[text, setText]= useState()

    const textOnChange = (event) => {
        setText(event.target.value);
    }

  return (
    <div>

        <input type='text' value={text} onChange={textOnChange} />
        <button>actualizar productp</button>
        <p>texto: {text}</p>
        <Link to="/Usuario">Haz clic para ver la página Usuario</Link>
        <Link to="/">Haz clic para ver la página de Home</Link>
    </div>
  )
}